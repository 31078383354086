import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import { orderOriginIsWtp, submitOrder } from '../concerns/patient_shared'

export default class extends Controller {
  static values = { promos: Boolean, trials: Boolean, promoCode: String }

  connect() {
    if (orderOriginIsWtp() || !isPresent(document.getElementById('shopping-cart'))) {return};
    this.checkCartForPromos();
    this.checkCartForTrials();
  }

  checkCartForPromos() {
    const cartProductIdEl = this.getCartForm('.product_id');
    let promoIdsArray = [];
    Array.from(cartProductIdEl).forEach(productEl => {
      if (this.promoProductIdsArray.includes(productEl.innerHTML)) {
        promoIdsArray.push(productEl.innerHTML);
      };
    });
    if (promoIdsArray.length < 1) { return };
    this.promosValue = true;
    this.promoCodeValue = promoIdsArray.toString()
  }

  checkCartForTrials() {
    const cartProductNameEl = this.getCartForm('.product_description');
    const cartHasTrialProducts = Array.from(cartProductNameEl).some(productName => productName.innerHTML);
    if (!cartHasTrialProducts) { return };
    this.trialsValue = true;
  }

  getCartForm(class_name) {
    return document.getElementById('shopping-cart').querySelectorAll(`form ${class_name}`);
  }

  get promoProductIdsArray() {
    return ['SP00005', 'SP00018']
  }

  addProdToCart() {
    submitOrder()
  }

  completeOrder() {
    try {
      const inventory = document.querySelector(".exceed-inventory").dataset.inventory;
      const inventoryModalBtn = document.getElementById('inventory-modal-btn');
      if (isPresent(inventory) && inventory == "false") {
       inventoryModalBtn.click();
      } else {
       confirmOrderForm();
      };
    } catch (error) {
      console.error(`There was an error with completeOrder(). Error: ${error}`)
    }
  }

  inventorySubstitution() {
    document.querySelector('#order_sub_submit').click();
  }

  completeOrderPrecheck() {
    this.promoTrialRequestCheck();
  }

  get getPromoTrialPayload() {
    const orderId = document.getElementById('address-id-holder').dataset.id;
    return {
      id: orderId,
      promo: this.promosValue,
      trial: this.trialsValue,
      promoCode: this.promoCodeValue
    };
  }

  async promoTrialRequestCheck() {
    const requestUrl = '/promo_trial_product_check';
    const payload = this.getPromoTrialPayload;
    try {
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      if (!data.error) {
        if(data.exceed_limit) {
          $('#trial_product_modal').modal('show');
        } else if(data.type == 'promo' && !data.eligible) {
          alertErrorFlash(`${data.msg}`)
        } else {
          this.completeOrder();
        }
      } else {
        alertErrorFlash('An error has occurred.')
        console.error(`Error with request to check_for_trial_product method. Error Response: ${data.error}`)
      }
    } catch (error) {
      console.error(error);
    }
  }
}